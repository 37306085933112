<template>
  <div class="container pt-3">
    <div class="text-center position-relative h-100 w-100 card-design">
      <div class="card-main">
        <div class="card-main-sub">
          <img v-bind:src="img" class="logo-nav" />
          <div
            class="d-flex flex-column align-items-center justify-content-between mt-5 pt-3 content"
          >
            <div>
              <div v-show="pImgTarot">
                <img v-bind:src="imgTarot" class="tarot-cards mt-3" />
              </div>
              <h5 class="text-title mt-4 mb-3">{{ subTitle }}</h5>
              <img v-bind:src="imgHr" class="img-hr" />
              <div v-show="pSuccess">
                <img v-bind:src="iconSuccess" width="50" class="my-3" />
              </div>
              <h4 class="text-title-sub mt-2">{{ title }}</h4>
              <p class="fs-3">{{ detail }}</p>
            </div>
            <div v-show="btn">
              <router-link
                :to="{
                  name: linkName ? linkName : '',
                  params: { id: paramsID },
                }"
              >
                <button type="button" class="btn btn-danger px-5 text-nowrap">
                  {{ btnName }}
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const imgLink = require("../assets/images/logo.png");
const imgTarot = require("../assets/images/card.png");
const imgHr = require("../assets/images/hr.png");
const iconSuccess = require("../assets/images/icon-success.png");
export default {
  name: "CardDesignTemplate",
  data() {
    return {
      img: imgLink,
      imgTarot: imgTarot,
      imgHr: imgHr,
      iconSuccess: iconSuccess,
    };
  },
  props: {
    pImgTarot: Boolean,
    title: String,
    subTitle: String,
    detail: String,
    btn: Boolean,
    linkName: String,
    btnName: String,
    pSuccess: Boolean,
    paramsID: Number,
  },
};
</script>