import { createRouter, createWebHistory } from 'vue-router'
import Main from '../views/Main.vue'
import Random from '../views/Random.vue'
import Intro from '../views/Intro.vue'
import Content from '../views/Content.vue'
import SelectQuestion from '../views/SelectQuestion.vue'
import Question from '../views/Question.vue'
import Summary from '../views/Summary.vue'
import Reward from '../views/Reward.vue'
import Success from '../views/Success.vue'
import TableHistory from '../views/TableHistory.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/random',
    name: 'Random',
    component: Random,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/intro/:id',
    name: 'Intro',
    component: Intro,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/content/:id',
    name: 'ContentCard',
    component: Content,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/select-question/:id',
    name: 'SelectQuestion',
    component: SelectQuestion,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/question/:id',
    name: 'Question',
    component: Question,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/summary/:id',
    name: 'Summary',
    component: Summary,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/success/:id',
    name: 'Success',
    component: Success,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/reward',
    name: 'Reward',
    component: Reward,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
  {
    path: '/table-history',
    name: 'TableHistory',
    component: TableHistory,
    meta: {
      hideNavElement: true,
      hideFooterElement: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
