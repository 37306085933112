import { createStore } from 'vuex'

export default createStore({
  state: {
    firstName: 'John',
    lastName: 'Doe',
    lessonID: null,
    score: 0,
    custUID: null
  },
  getters: {
    fullName: function (state) {
      return `${state.firstName} ${state.lastName}`
    },
    getLessonID: function (state) {
      return state.lessonID;
    },
    getScore: function (state) {
      return state.score;
    },
    getCustUID: function (state) {
      return state.custUID;
    }
  },
  mutations: {
    setLessonID: function (state, lessonID) {
      state.lessonID = lessonID;
    },
    setScore: function (state, score) {
      state.score = score;
    },
    setCustUID: function (state, custUID) {
      state.custUID = custUID;
    }
  }
})