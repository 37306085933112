<template>
  <div class="container pt-3 main">
    <div class="text-center position-relative h-100 w-100">
      <!-- <div class="card-main" />
      <div class="card-main-border" />
      <div class="card-main-sub" />
      <div class="card-main-sub-border" /> -->
      <div class="card-main-heard">
        <div class="card-main-heard-border">
          <img v-bind:src="img" class="logo" />
        </div>
      </div>
      <div class="card-main">
        <div class="card-main-sub">
          <div class="box">
            <div class="row">
              <div class="col-6" v-for="(item, index) in choice" :key="index">
                <router-link
                  :to="{ name: item.linkName, params: { id: item.paramsId } }"
                >
                  <div class="card position-relative" :class="item.color">
                    <p class="card-text">{{ item.title }}</p>
                    <div v-if="item.status === 'done'">
                      <img v-bind:src="imgBtnSuccess" class="img-btn" />
                    </div>
                    <div v-else>
                      <img v-bind:src="imgBtn100" class="img-btn" />
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="text-center">
            <a href="https://unilevermitrtae.com/Home/Profile">
              <button type="button" class="btn btn-danger px-5 text-nowrap">
                กลับไปหน้าแรก
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const imgLink = require("../assets/images/logo.png");
const imgBtn100 = require("../assets/images/btn-100.png");
const imgBtnSuccess = require("../assets/images/btn-success.png");
import axios from "axios";
export default {
  name: "MenuTemplate",
  data() {
    return {
      img: imgLink,
      imgBtn100: imgBtn100,
      imgBtnSuccess: imgBtnSuccess,
      title: "เรียนรู้พาราย",
      choice: [],
    };
  },
  methods: {
    async loadingLessonSatus() {
      let lessonData = [
        {
          title: "การตลาดฉบับโชห่วย",
          status: "done",
          linkName: "Intro",
          color: "green",
          paramsId: 1,
          lessonCode: "001",
        },
        {
          title: "การจัดการสินค้าดียังไง",
          status: "pending",
          linkName: "Intro",
          color: "red",
          paramsId: 2,
          lessonCode: "002",
        },
        {
          title: "จัดเรียงสินค้ายังไงให้น่าซื้อ",
          status: "pending",
          linkName: "Intro",
          color: "blue",
          paramsId: 3,
          lessonCode: "003",
        },
        {
          title: "โชห่วยยุค 4.0",
          status: "pending",
          linkName: "Intro",
          color: "yellow",
          paramsId: 4,
          lessonCode: "004",
        }
      ];
      try {
        const respones = await axios.get(
          `${process.env.VUE_APP_BASE_API_URL}Plugin/getLessonStatus?custUID=${this.$store.getters.getCustUID}`
        );
        const responData = await respones.data.data;

        responData.forEach((data) => {
          lessonData.forEach((item) => {
            if (item.lessonCode === data.lessonCode) {
              data.isPass ? (item.status = "done") : (item.status = "pending");
            }
          });
        });

        this.choice = lessonData;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.loadingLessonSatus();
    console.log(this.$store.getters.getCustUID);
  },
  created() {
    if (this.$route.query.custUID) {
      this.$store.commit("setCustUID", this.$route.query.custUID);
    }
  },
};
</script>
