<template>
  <div class="container pt-3">
    <div class="text-center position-relative h-100 w-100 card-design">
      <div class="card-main">
        <div class="card-main-sub">
          <img v-bind:src="img" class="logo-nav" />
          <div
            class="d-flex flex-column align-items-center justify-content-between mt-5 pt-3 content"
          >
            <div>
              <img v-bind:src="imgCon" width="250" />
              <h1 class="text-title mt-4">ยินดีด้วย</h1>
              <h5 class="text-title">ได้รับมิตรแท้</h5>
              <div
                class="d-flex align-items-center justify-content-center mt-3"
              >
                <img v-bind:src="imgCoin" width="25" />
                <h2 class="fw-bold ms-2 mb-0">{{ score }}</h2>
              </div>
              <p class="mb-0 text-center mt-4 fs-7">
                *หมายเหตุ :
                ท่านจะได้รับพอยท์จากการเรียนรู้เพียงครั้งเดียวเท่านั้น
              </p>
            </div>
            <div class="text-center">
              <router-link :to="{ name: 'Main' }">
                <button type="button" class="btn btn-danger px-5 text-nowrap">
                  กลับไปหน้าแรก
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const imgLink = require("../assets/images/logo.png");
const imgCon = require("../assets/images/certification.png");
const imgCoin = require("../assets/images/coin.png");
export default {
  name: "RewardTemplate",
  data() {
    return {
      img: imgLink,
      imgCon: imgCon,
      imgCoin: imgCoin,
      title: "หน้าสรุปเนื้อหา",
      content: [
        "เข้าร่วมโครงการส่งเสริมของรัฐบาล เช่น ธงฟ้าประชารัฐ",
        "มีช่องทางในการสื่อการกับลูกค้าโดยตรงผ่าน LINE",
        "มีกิจกรรมกระตุ้นยอดขายภายในร้าน",
        "มีโปรโมชั่นพิเศษ สำหรับลูกค้าที่ซื้อสินค้าจำนวนมาก",
      ],
      score: this.$store.getters.getScore,
    };
  },
};
</script>