<template>
  <div v-if="$route.params.id == 1">
    <div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <CardDesign
            :title="'การตลาดฉบับโชห่วย'"
            :subTitle="'เนื้อหา 1/4'"
            :paramsID="1"
            :detail="content.choice1"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'การตลาดฉบับโชห่วย'"
            :subTitle="'เนื้อหา 2/4'"
            :paramsID="1"
            :detail="content.choice2"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'การตลาดฉบับโชห่วย'"
            :subTitle="'เนื้อหา 3/4'"
            :paramsID="1"
            :detail="content.choice3"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'การตลาดฉบับโชห่วย'"
            :subTitle="'เนื้อหา 4/4'"
            :detail="content.choice4"
            :btn="true"
            :paramsID="1"
            :btnName="'เริ่มทำแบบทำสอบ'"
            :linkName="'SelectQuestion'"
          />
        </div>
      </div>
      <div v-if="stopCard != 4">
        <button
          @click="addStep(1)"
          class="btn carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
          :disabled="disableBtn"
        >
          ต่อไป
          <img v-bind:src="img" class="ms-2" width="15" />
        </button>
      </div>
    </div>
  </div>

  <div v-else-if="$route.params.id == 2">
    <div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <CardDesign
            :title="'การจัดการสินค้าดียังไง'"
            :subTitle="'เนื้อหา 1/4'"
            :paramsID="2"
            :detail="content2.choice1"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'การจัดการสินค้าดียังไง'"
            :subTitle="'เนื้อหา 2/4'"
            :paramsID="2"
            :detail="content2.choice2"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'การจัดการสินค้าดียังไง'"
            :subTitle="'เนื้อหา 3/4'"
            :paramsID="2"
            :detail="content2.choice3"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'การจัดการสินค้าดียังไง'"
            :subTitle="'เนื้อหา 4/4'"
            :detail="content2.choice4"
            :btn="true"
            :paramsID="2"
            :btnName="'เริ่มทำแบบทำสอบ'"
            :linkName="'SelectQuestion'"
          />
        </div>
      </div>
      <div v-if="stopCard != 4">
        <button
          @click="addStep(1)"
          class="btn carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
          :disabled="disableBtn"
        >
          ต่อไป
          <img v-bind:src="img" class="ms-2" width="15" />
        </button>
      </div>
    </div>
  </div>

  <div v-else-if="$route.params.id == 3">
    <div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <CardDesign
            :title="'จัดเรียงสินค้ายังไงให้น่าซื้อ'"
            :subTitle="'เนื้อหา 1/4'"
            :paramsID="3"
            :detail="content3.choice1"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'จัดเรียงสินค้ายังไงให้น่าซื้อ'"
            :subTitle="'เนื้อหา 2/4'"
            :paramsID="3"
            :detail="content3.choice2"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'จัดเรียงสินค้ายังไงให้น่าซื้อ'"
            :subTitle="'เนื้อหา 3/4'"
            :paramsID="3"
            :detail="content3.choice3"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'จัดเรียงสินค้ายังไงให้น่าซื้อ'"
            :subTitle="'เนื้อหา 4/4'"
            :detail="content3.choice4"
            :btn="true"
            :paramsID="3"
            :btnName="'เริ่มทำแบบทำสอบ'"
            :linkName="'SelectQuestion'"
          />
        </div>
      </div>
      <div v-if="stopCard != 4">
        <button
          @click="addStep(1)"
          class="btn carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
          :disabled="disableBtn"
        >
          ต่อไป
          <img v-bind:src="img" class="ms-2" width="15" />
        </button>
      </div>
    </div>
  </div>

  <div v-else-if="$route.params.id == 4">
    <div id="carouselExample" class="carousel slide">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <CardDesign
            :title="'โชห่วยยุค 4.0'"
            :subTitle="'เนื้อหา 1/5'"
            :paramsID="4"
            :detail="content4.choice1"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'โชห่วยยุค 4.0'"
            :subTitle="'เนื้อหา 2/5'"
            :paramsID="4"
            :detail="content4.choice2"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'โชห่วยยุค 4.0'"
            :subTitle="'เนื้อหา 3/5'"
            :paramsID="4"
            :detail="content4.choice3"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'โชห่วยยุค 4.0'"
            :subTitle="'เนื้อหา 4/5'"
            :paramsID="4"
            :detail="content4.choice4"
          />
        </div>
        <div class="carousel-item">
          <CardDesign
            :title="'โชห่วยยุค 4.0'"
            :subTitle="'เนื้อหา 5/5'"
            :detail="content4.choice5"
            :btn="true"
            :paramsID="4"
            :btnName="'เริ่มทำแบบทำสอบ'"
            :linkName="'SelectQuestion'"
          />
        </div>
      </div>
      <div v-if="stopCard != 5">
        <button
          @click="addStep(1)"
          class="btn carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
          :disabled="disableBtn"
        >
          ต่อไป
          <img v-bind:src="img" class="ms-2" width="15" />
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import CardDesign from "../components/CardDesign.vue";
const imgNext = require("../assets/images/next.png");
export default {
  name: "ContentTemplate",
  data() {
    return {
      img: imgNext,
      disableBtn: false,
      content: {
        choice1:
          "เข้าร่วมโครงการของรัฐ เช่น ธงฟ้าประชารัฐ เป๋าตัง เพื่อเพิ่มความสามารถในการแข่งขันกลับกลุ่มร้านค้าปลีกต่าง ๆ",
        choice2:
          "สร้าง LINE เพื่อเป็นช่องทางการสื่อสาร กับคนในละแวกร้าน บอกข่าวสาร แจ้งโปรโมชั่นของร้าน และเพื่อให้คนในชุมชนสามารถเข้าถึงร้านโชห่วยได้ง่ายขึ้นมากกว่าเดิม",
        choice3:
          "มีกิจกรรมกระตุ้นยอดขาย เช่น จัดแสดงสินค้าใหม่ จัดให้เล่นเกมส์ในร้านเพื่อสร้างความสนุกสนาน สะสมแต้มให้ลูกค้าเข้ามาซื้อที่ร้านบ่อยๆ ออกบูธขายสินค้าตามตลาดนัด",
        choice4:
          "มีโปรโมชั่นพิเศษสำหรับลูกค้า ที่ซื้อสินค้าจำนวนมาก เพื่อให้ลูกค้ารู้สึกถึงความคุ้มค่า และกลับมาเป็นขาประจำร้านเราต่อไป",
      },
      content2: {
        choice1: "จัดสัดส่วนสินค้าให้มีบนชั้นวาง 80% และสต็อค 20% ",
        choice2:
          "จัดสินค้าแบบเข้าก่อนขายก่อน (FIFO) และเรียงสินค้าเก่ามาด้านหน้า สินค้าใหม่ไว้หลัง",
        choice3:
          "สถานที่จัดเก็บสินค้าจะต้องแยกประเภทสินค้า และน้ำหนักสินค้า เพื่อความสะดวกในการหา และขนย้ายสินค้า",
        choice4:
          "ใช้ POS ในการเช็คยอดขาย และสต็อคสินค้าภายในร้านได้ ทำให้ร้านมีสินค้าเพียงพอสำหรับการขายอยู่เสมอ และไม่สต็อคสินค้าเยอะเกินไป ",
      },
      content3: {
        choice1: "แบ่งหมวดหมู่สินค้า สินค้าส่วนใหญ่จะแบ่งหมวดหมู่เป็น 2 ประเภท ได้แก่ สินค้าอุปโภคและสินค้าบริโภค โดยสินค้าหมวดเดียวกันวางไว้ด้วยกัน เช่นวางน้ำยาล้างห้องน้ำ ไว้บริเวณเดียวกันกับน้ำยาซักผ้า",
        choice2:
          "วางสินค้าขายดีในระดับสายตา ควรวางสินค้าขายดีและสินค้าใหม่ไว้ในระดับสายตา เพื่อให้ลูกค้าได้เห็นสินค้าก่อนและหยิบซื้อไปลอง",
        choice3:
          "สินค้าชิ้นเล็กเรียงด้านบน ชิ้นใหญ่เอาไว้ด้านล่าง จะช่วยให้สินค้ามีความเป็นระเบียบ และลูกค้าสามารถหยิบสินค้าได้ง่ายขึ้น เช่นวางน้ำดื่มแพ็คไว้ด้านล่าง และวางขนมห่อเล็กไว้ด้านบน",
        choice4:
          "แยกสินค้าที่เป็นสารเคมีกับอาหารออกจากกัน สินค้าจำพวกยากันยุง, น้ำยาล้างห้องน้ำ, น้ำยาถูพื้น ไม่ควรนำไปวางไว้ใกล้กับอาหาร ลูกค้าอาจจะมองว่าสินค้าปนเปื้อนอาหารได้ ทำให้ไม่น่าซื้อ",
      },
      content4: {
        choice1: "S-Sustainability (การทำธุรกิจอย่างยั่งยืน) เพื่อให้ธุรกิจมีความยั่งยืนและเติบโต คือการมีเป้าหมายที่ชัดเจน เช่น เพิ่มยอดขาย ลดค่าใช้จ่ายในการดำเนินธุรกิจ หรือเพิ่มผลกำไรในแต่ละเดือน",
        choice2:
          "M-Manageable (เก่งการจัดการ) มีการวางแผนเพื่อบรรลุเป้าหมายที่ตั้งเอาไว้ เช่นการสังเกตุว่าร้านเรามีจุดแข็งใดที่สามารถพัฒนาได้ หรือมีจุดอ่อนตรงไหนที่ต้องแก้ไข เพื่อให้เป้าหมายที่วางไว้สำเร็จ",
        choice3:
          "A-Adaptable (ปรับตัวว่องไว) การปรับตัวให้สามารถปรับปรุงธุรกิจ ให้เหมาะกับการเปลี่ยนแปลงของตลอดเวลา เช่น ในช่วงโควิดอาจจะมีการนำชุดตรวจโควิดเข้ามาขาย หรือมีบริการส่งสินค้าและอาหารให้กับลูกค้าได้ด้วย",
        choice4:
          "R-Relationship (มีความสัมพันธ์ที่ดีเยี่ยมในชุมชน) การให้บริการลูกค้าอย่างดี สร้างประสบการณ์การซื้อสินค้าที่ดี ติดต่อสื่อสารกับลูกค้าอย่างสม่ำเสมอ",
        choice5:
          "T-Technology (เทคโนโลยี) การใช้เทคโนโลยีสามารถทำได้หลายวิธี เช่น ใช้โซเชียลมีเดียเช่น Line หรือ TikTok ในการติดต่อสื่อสารกับลูกค้า, ใช้ระบบบาร์โค้ดเพื่อสแกนและติดตามสินค้าในคลังเพื่อลดความผิดพลาดในการนับสินค้า หรือ โดยใช้ระบบการชำระเงินออนไลน์ ผ่าน QR code เพื่อเพิ่มความสะดวกให้กับลูกค้า",
      },
      stopCard: 1,
    };
  },
  components: {
    CardDesign,
  },
  methods: {
    addStep(value) {
      this.disableBtn = true;
      setTimeout(() => {
        this.disableBtn = false;
      }, 800);
      this.stopCard += value;
      console.log("stopCard", this.stopCard);
    },
  },
};
</script>
