<template>
  <div class="template-body">
    <Navbar v-if="!$route.meta.hideNavElement" />
    <!-- <div class="px-2"> -->
    <router-view />
    <!-- </div> -->
    <Footer v-if="!$route.meta.hideFooterElement" />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>