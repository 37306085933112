<template>
  <div class="container pt-3">
    <div class="text-center position-relative h-100 w-100 card-design">
      <div class="card-main">
        <div class="card-main-sub">
          <img v-bind:src="img" class="logo-nav" />
          <div class="mt-5 pt-3 content">
            <img v-bind:src="imgHr" class="img-hr mb-3" />
            <div v-if="$route.params.id == 1">
              <div v-show="isVisible1">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice1.title }}
                </h5>

                <div v-if="isQuestion1 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice1.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question1"
                        name="question1"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible2">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice2.title }}
                </h5>

                <div v-if="isQuestion2 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice2.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question2"
                        name="question2"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible3">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice3.title }}
                </h5>

                <div v-if="isQuestion3 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion3()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice3.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question3"
                        name="question3"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion3($route.params.id)"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>
            </div>

            <div v-else-if="$route.params.id == 2">
              <div v-show="isVisible1">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice21.title }}
                </h5>

                <div v-if="isQuestion1 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice21.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question1"
                        name="question1"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible2">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice22.title }}
                </h5>

                <div v-if="isQuestion2 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice22.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question2"
                        name="question2"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible3">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice23.title }}
                </h5>

                <div v-if="isQuestion3 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion3()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice23.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question3"
                        name="question3"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion3($route.params.id)"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>
            </div>
            
            <div v-else-if="$route.params.id == 3">
              <div v-show="isVisible1">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice31.title }}
                </h5>

                <div v-if="isQuestion1 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice31.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question1"
                        name="question1"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible2">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice32.title }}
                </h5>

                <div v-if="isQuestion2 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice32.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question2"
                        name="question2"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible3">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice33.title }}
                </h5>

                <div v-if="isQuestion3 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion3()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice33.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question3"
                        name="question3"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion3($route.params.id)"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>
            </div>
          
            <div v-else-if="$route.params.id == 4">
              <div v-show="isVisible1">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice41.title }}
                </h5>

                <div v-if="isQuestion1 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice41.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question1"
                        name="question1"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion1()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible2">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice42.title }}
                </h5>

                <div v-if="isQuestion2 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice42.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question2"
                        name="question2"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion2()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>

              <div v-show="isVisible3">
                <h5 class="text-center fw-bold mb-3">
                  {{ choice43.title }}
                </h5>

                <div v-if="isQuestion3 == true">
                  <img v-bind:src="iconDanger" width="50" />
                  <p class="mt-3">
                    ตอบผิด สามารถตอบใหม่ได้ โดยกดที่ปุ่มตอบอีกครั้ง
                  </p>

                  <button
                    @click="reFnQuestion3()"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบกลับอีกครั้ง
                  </button>
                </div>
                <div v-else>
                  <div class="card-question">
                    <label
                      v-for="(item, index) in choice43.question"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :value="item.question"
                        v-model="question3"
                        name="question3"
                      />
                      <label class="label">
                        <span>{{ item.name }}</span>
                      </label>
                    </label>
                  </div>
                  <button
                    @click="fnQuestion3($route.params.id)"
                    class="btn btn-danger fix-btn-bottom px-5 text-nowrap"
                    type="button"
                  >
                    ตอบ
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const iconDanger = require("../assets/images/icon-danger.png");
const iconSuccess = require("../assets/images/icon-success.png");
const imgLink = require("../assets/images/logo.png");
const imgHr = require("../assets/images/hr.png");
export default {
  name: "SelectQuestionTemplate",
  data() {
    return {
      iconSuccess: iconSuccess,
      iconDanger: iconDanger,
      img: imgLink,
      imgHr: imgHr,

      salary1: false,
      isVisible1: true,
      isVisible2: false,
      isVisible3: false,

      isQuestion1: false,
      isQuestion2: false,
      isQuestion3: false,
      score: 100,
      choice1: {
        no: 1,
        title:
          "ข้อใดไม่ใช่ประโยชน์ของการใช้สื่อออนไลน์อย่าง LINE ในการประชาสัมพันธ์",
        question: [
          {
            name: "สามารถสื่อการกับลูกค้าได้โดยตรง",
            value: 1,
            question: false,
          },

          {
            name: "สามารถส่งข้อมูล เช่น สินค้าใหม่ กิจกรรมในร้าน ให้ลูกค้าได้",
            value: 2,
            question: false,
          },
          {
            name: "สามารถเอาไว้คุยแชทกับเพื่อนได้",
            value: 3,
            question: true,
          }
        ]
      },
      choice2: {
        no: 2,
        title: "สิ่งใดต่อไปนี้ไม่ถือเป็นกิจกรรมกระตุ้นยอดขาย",
        question: [
          {
            name: "ทำโปรโมชั่นซื้อครบ 300 บาท แถมฟรีขันตักน้ำ",
            question: false,
          },
          {
            name: "ทำบัตรสะสมแต้มให้กับลูกค้าที่มาซื้อชาไข่มุก 10 แก้วแถมฟรี 1",
            question: false,
          },
          {
            name: "นับสต็อกหลังบ้านอยู่สม่ำเสมอ",
            question: true,
          }
        ]
      },
      choice3: {
        no: 3,
        title: "การเข้าร่วมโครงการของรัฐฯ มีประโยชน์อย่างไรกับร้านโชห่วย",
        question: [
          {
            name: "เพิ่มความสามารถในการแข่งขันกับร้านค้าปลีก",
            question: true,
          },
          {
            name: "ทำให้ขายสินค้าได้กำไรน้อยลง",
            question: false,
          },
          {
            name: "ทำให้ต้องจัดการระบบหลังบ้านยุ่งยากขึ้น",
            question: false,
          }
        ]
      },

      choice21: {
        no: 1,
        title:
          "สินค้ายูนิลีเวอร์เหมือนกัน สามารถวางบรีส และคนอร์ไปร่วมกันได้หรือไม่",
        question: [
          {
            name: "ได้ เนื่องจากคนอร์และบรีสเป็นสินค้าที่มาจากบริษัทเดียวกัน",
            value: 1,
            question: false,
          },
          {
            name: "ไม่ได้ เนื่องจากบรีสเป็นสินค้าสำหรับทำความสะอาด และคนอร์เป็นอาหารทำให้วางด้วยกันไม่ได้",
            value: 2,
            question: true,
          }
        ]
      },
      choice22: {
        no: 2,
        title: "การจัดการสินค้าแบบ FIFO คืออะไร",
        question: [
          {
            name: "เข้าหลัง ออกก่อน",
            question: false,
          },
          {
            name: "เข้าก่อน ออกก่อน ",
            question: true,
          },
          {
            name: "เข้าก่อน ออกหลัง",
            question: false,
          }
        ]
      },
      choice23: {
        no: 3,
        title: "สัดส่วนสินค้าที่โชว์ กับสินค้าที่จัดเก็บ ควรเป็นอย่างไร",
        question: [
          {
            name: "50 : 50 ",
            question: false,
          },
          {
            name: "70 : 30 ",
            question: false,
          },
          {
            name: "80 : 20 ",
            question: true,
          }
        ]
      },

      choice31: {
        no: 1,
        title:
          "สินค้าใดสามารถวางไว้ติดกับสินค้าประเภทอาหารได้",
        question: [
          {
            name: "น้ำยาล้างห้องน้ำ",
            value: 1,
            question: false,
          },

          {
            name: "ยากันยุง",
            value: 2,
            question: false,
          },
          {
            name: "หน้ากากอนามัย",
            value: 3,
            question: true,
          }
        ]
      },
      choice32: {
        no: 2,
        title: "การวางสินค้าที่ระดับสายตา มีประโยชน์อย่างไร",
        question: [
          {
            name: "ลูกค้าเห็นสินค้าได้ง่าย ทำให้น่าซื้อไปลอง",
            question: false,
          },
          {
            name: "ช่วยผลักดันสินค้าใหม่ๆ ให้ลูกค้าได้เห็นมากขึ้น",
            question: false,
          },
          {
            name: "ถูกทุกข้อ",
            question: true,
          }
        ]
      },
      choice33: {
        no: 3,
        title: "น้ำเปล่า 5 ลิตรควรวางไว้ที่ใดของชั้น",
        question: [
          {
            name: "ชั้นด้านล่างสุด",
            question: true,
          },
          {
            name: "วางไว้ตรงระดับสายตา",
            question: false,
          },
          {
            name: "วางไว้ชั้นบนสุด",
            question: false,
          }
        ]
      },

      choice41: {
        no: 1,
        title:
          "ข้อใดไม่ใช่ S-M-A-R-T model",
        question: [
          {
            name: "T-Technology (เทคโนโลยี)",
            value: 1,
            question: false,
          },

          {
            name: "R-Relationship (การสร้างความสัมพันธ์)",
            value: 2,
            question: false,
          },
          {
            name: "S-Strong (ความแข็งแกร่ง)",
            value: 3,
            question: true,
          }
        ]
      },
      choice42: {
        no: 2,
        title: " ในช่วงโควิดร้านสมชาย มีการนำชุดตรวจโควิดเข้ามาขาย หรือมีบริการส่งสินค้าและอาหารให้กับลูกค้า ถือเป็นการใช้ Smart model ในข้อใด",
        question: [
          {
            name: "S-Sustainability (ความยั่งยืน)",
            question: false,
          },
          {
            name: "A-Adaptable (ปรับตัวว่องไว)",
            question: true,
          },
          {
            name: "M-Manageable (เก่งการจัดการ)",
            question: false,
          }
        ]
      },
      choice43: {
        no: 3,
        title: "ข้อใดไม่ใช่การนำ T-Technology (เทคโนโลยี) มาปรับใช้กับร้านโชห่วย",
        question: [
          {
            name: "ร้านมิตรแท้นำกล้วยตาก ที่เป็นสินค้าในชุมชน มาไลฟ์ขายของใน TikTok เพื่อเพิ่มยอดขายออนไลน์ให้กับร้าน และช่วยชุมชนไปในตัว",
            question: false,
          },
          {
            name: "ร้านมิจฉา รับเฉพาะเงินสดเท่านั้น ไม่รับคิดเงินแบบ QR Code",
            question: true,
          },
          {
            name: "ร้านมิตรดีแสกนบาร์โค้ดสินค้าทุกครั้งที่มีการรับสินค้าเข้าร้าน เพื่อให้ง่ายต่อการนับจำนวน ติดตามสินค้าที่มีในร้านสม่ำเสมอ",
            question: false,
          }
        ]
      }
    };
  },
  methods: {
    calculateScore() {
      this.score += 1;
    },
    fnSetScore() {
      console.log("Total score",this.score);
      this.$store.commit("setScore", this.score);
    },
    fnQuestion1() {
      if (!this.question1) {
        this.isQuestion1 = true;
        this.isQuestion2 = false;
        this.isQuestion3 = false;
      } else {
        this.isVisible1 = false;
        this.isVisible2 = true;
        this.isVisible3 = false;
      }
    },
    reFnQuestion1() {
      this.isQuestion1 = !this.isQuestion1;
    },

    fnQuestion2() {
      if (!this.question2) {
        this.isQuestion1 = false;
        this.isQuestion2 = true;
        this.isQuestion3 = false;
      } else {
        this.isVisible1 = false;
        this.isVisible2 = false;
        this.isVisible3 = true;
      }
    },
    reFnQuestion2() {
      this.isQuestion2 = !this.isQuestion2;
    },

    fnQuestion3(value) {
      if (!this.question3) {
        this.isQuestion1 = false;
        this.isQuestion2 = false;
        this.isQuestion3 = true;
      } else {
        this.fnSetScore();
        if(value == 1)
        {
          this.$router.push("/success/1");
        }
        else if(value == 2)
        {
          this.$router.push("/success/2");
        }
        else if(value == 3)
        {
          this.$router.push("/success/3");
        }
        else
        {
          this.$router.push("/success/4");
        }
      }
    },
    reFnQuestion3() {
      this.isQuestion3 = !this.isQuestion3;
    },
  },
};
</script>