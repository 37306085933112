<template>
  <div class="container pt-3">
    <h4 class="text-center">ทำนาย MARKETING</h4>
    <p>
      This is the first item's accordion body. It is shown by default, until the
      collapse plugin adds the appropriate classes that we use to style each
      element. These classes control the overall appearance, as well as the
      showing and hiding via CSS transitions. You can modify any of this with
      custom CSS or overriding our default variables. It's also worth noting
      that just about any HTML can go within the <code>.accordion-body</code>,
      though the transition does limit overflow.
    </p>
    <div class="text-center">
      <router-link :to="{ name: 'Summary' }">
        <button type="button" class="btn btn-secondary px-5">ต่อไป</button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionTemplate",
};
</script>