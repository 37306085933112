<template>
  <div>
    <img src="/assets/images/logo.png" />
  </div>
</template>

<script>
export default {
  name: "NavbarTemplate",
};
</script>